export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        edit: false,
        delete: false
      }
    }
  },
  created() {
    const statuses = []
    const baseRule = 'contract'

    this.initPermision(`${baseRule}.get`,'list',statuses)
    this.initPermision(`${baseRule}.post`,'create',statuses)
    this.initPermision(`${baseRule}.patch`,'edit',statuses)
    this.initPermision(`${baseRule}.delete`,'delete',statuses)
  }
}