<template>
  <div style="padding-left: 1.75rem;">
    <div class="col2_head">
      <span><router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link> {{ $route.name }}<div class="subHeadTitle">{{ $route.name }}</div></span>
      <div class="right" style="margin-top: 1.2rem;">
        <div v-if="PERMISIONS.create" class="right">
          <div @click="addItem" class="Button1">
            <div class="icon">
              <img @load="imgLoaded" src="../assets/media/for_table/circle_ADD_new_etap.svg" alt="">
            </div>
            <span class="Btn_content">
              Adaugă tip contract
            </span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="PERMISIONS.list">
      <table-parent
        :reloadCount="reloadTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :apiModule="tableModule"
        :apiModuleName="'CONTRACT'"
        :tableMinimizeHead="true"
        :tableSpread="true"
        @Btn2="editItem"
        @Btn3="deleteItem"
        @totalRecords="x => (totalRecords = x)"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
  import PermisionsMixin from '@/mixins/contractTypesPermMixin.js'
  import { CONTRACT } from '../api.js'
  import DialogModal from '@/components/DialogModal'
  import TableParent from '@/components/TableParent'

  export default {
    $Title() {
      return `ConectX - ${this.$route.name}`
    },
    mixins: [PermisionsMixin],
    components: {
      TableParent
    },
    data() {
      return {
        reloadTable: 0,
        tableHead: [
          {title:'Denumire tip contract',sort:true,
          queryKey: 'contractType',
          bottomHead: {
              quikFiltre: true
          }}
        ],
        tableModule: CONTRACT.get,
        totalRecords: 0,
      }
    },
    methods: {
      reloadPage() {
        this.reloadTable++
        this.totalRecords = 0
      },
      deleteItem(item) {
        if(!Number.isInteger(item?.id)) {
          this.$toastr.e('Anunțați administratorul.')
          return
        }
        
        this.$modal.show(
          DialogModal,
          {
            target: 'DeleteContract',
            title: 'Ștergere tip contract',
            content: '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi Tipul de Contract selectat?</p></span>',
            closeBtnText: 'Anulează',
            button: {
              type: 3,
              value: 'Șterge',
              handler: () => {
                const err = msg => this.$toastr.e(msg || 'Ștergerea tipului de contract a eșuat.')

                this.setSafeLoad()
                CONTRACT.delete(item.id).then(res => {
                  if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s('Tipul de contract a fost șters cu succes.')
                  } else {
                    err()
                  }
                  this.setLoad()
                  this.reloadPage()
                }).catch(msg => {
                  err(msg)
                  this.setLoad()
                  this.reloadPage()
                })
              }
            }
          },
          {
            name: 'DeleteContract',
            adaptive: true,
            width: '650',
            height: '400'
          }
        )
      },
      editItem(item) {
        if(!Number.isInteger(item?.id)) {
          this.$toastr.e('Anunțați administratorul.')
          return
        }

        this.$modal.show(
          DialogModal,
          {
            target: 'EditContract',
            title: 'Modifică sursă de finanțare',
            inputs: [
              {
                label: 'Denumire tip contract',
                id: 'contractType',
                errReason: 'Introduceți denumirea tipului contractului.',
              }
            ],
            closeBtnText: 'Anulează',
            initialInputsData: item,
            button: {
              type: 2,
              value: 'Modifică',
              handler: sendData => {
                sendData = this.getEdited(sendData, item, true)
                
                const error = msg => {
                  this.$toastr.e(msg || 'Modificarea sursei de finanțare a eșuat.')
                  this.reloadPage()
                  this.setLoad()
                }

                this.setSafeLoad(12000)
                CONTRACT.edit(item.id, sendData)
                  .then(res => {
                    if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                      this.$toastr.s('Sursa de finanțare a fost modificată.')
                      this.reloadPage()
                      this.setLoad()
                    } else {
                      error()
                    }
                  })
                  .catch(error)
              },
            },
          },
          {
            name: 'EditContract',
            adaptive: true,
            width: '650',
            height: '800',
          }
        )
      },
      addItem() {
        this.$modal.show(
          DialogModal,
          {
            target: 'AddContract',
            title: 'Adaugă tip contract',
            inputs: [
              {
                label: 'Denumire tip contract',
                id: 'contractType',
                errReason: 'Introduceți denumirea tipului contractului.',
              }
            ],
            closeBtnText: 'Anulează',
            button: {
              type: 2,
              value: 'Adaugă',
              handler: sendData => {
                const error = msg => {
                  this.$toastr.e(msg || 'Adăugarea tipului contractului a eșuat.')
                  this.reloadPage()
                  this.setLoad()
                }

                this.setSafeLoad(12000)
                CONTRACT.add(sendData)
                  .then(res => {
                    if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                      this.$toastr.s('Tipul de contract a fost adăugat.')
                      this.reloadPage()
                      this.setLoad()
                    } else {
                      error()
                    }
                  })
                  .catch(error)
              },
            },
          },
          {
            name: 'AddContract',
            adaptive: true,
            width: '650',
            height: '800',
          }
        )
      },
      prepareData(row) {
        const spacer = window.innerWidth > 900
        
        const preparation = [row.contractType || '-']

        if(spacer) {
          preparation.push({spacer:true})
        }

        const x = this.PERMISIONS
        const prepareBtn = []

        const uuid = row.createdByUser
        const checkOwner = o => {
          if(!o) return false
          if(o === true) return true
          if(o == uuid) {
            return true
          }
          if(Array.isArray(o) && o[1] == uuid) {
            return true
          }
          return false
        }

        if(checkOwner(x.edit)) {
          prepareBtn.push(2)
        }
        if(checkOwner(x.delete)) {
          prepareBtn.push(3)
        }
        if(prepareBtn.length) {
          preparation.push([prepareBtn])
        }

        return preparation
      },
    },
    async created() {
      if(!this.PERMISIONS.list) {
        return
      }
      if(window.innerWidth > 900) {
        this.tableHead.push({spacer:true})
      }
      if(this.checkParamsInObj(this.PERMISIONS, ['edit', 'delete'])) {
        this.tableHead.push({spacer:true,minimize:1,fixed:{
          right: true
        },bottomHead: {
            quikFiltre: 'clear'
        }})
      }
    }
  }
</script>